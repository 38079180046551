import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#009BDA',
        secondary: '#263238',
        accent: '#566d77',
        error: '#F34A4A',
        info: '#009BDA',
        success: '#26A69A',
        warning: '#EDB83D',
      },
    },
  },
});
