import { Auth } from 'aws-amplify';
import removeNamespace from './store-helper';

export const AUTH_STORE = 'auth';

export const NamespacedAuthTypes = {
  state: {
    user: 'auth/user',
    loggedIn: 'auth/loggedIn',
    authError: 'auth/authError',
  },
  getters: {
    token: 'auth/token',
    email: 'auth/email',
  },
  mutations: {
    authentication: 'auth/authentication',
    newPasswordRequried: 'auth/newPasswordRequried',
    authenticationError: 'auth/authenticationError',
    logout: 'auth/logout',
    logoutError: 'auth/logoutError',
    refreshToken: 'auth/refreshToken',
  },
  actions: {
    authenticate: 'auth/authenticate',
    provideNewPassword: 'auth/provideNewPassword',
    logout: 'auth/logout',
    refreshToken: 'auth/refreshToken',
  },
  notifications: {
    AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
    AUTHENTICATE_NEW_PASSWORD: 'AUTHENTICATE_NEW_PASSWORD',
    PROVIDE_NEW_PASSWORD_SUCCESS: 'PROVIDE_NEW_PASSWORD_SUCCESS',
    LOGOUT_SUCCEED: 'LOGOUT_SUCCEED',
  },
  constants: {
    LOCAL_STORAGE_USER: 'user',
    LOCAL_STORAGE_LOGGED_IN: 'loggedIn',
  },
};

export const AuthTypes = removeNamespace('auth/', NamespacedAuthTypes);

export const auth = {
  state: {
    [AuthTypes.state.user]: JSON.parse(
      localStorage.getItem(AuthTypes.constants.LOCAL_STORAGE_USER),
    ) || null,
    [AuthTypes.state.loggedIn]: JSON.parse(
      localStorage.getItem(AuthTypes.constants.LOCAL_STORAGE_LOGGED_IN),
    ) || false,
    [AuthTypes.state.authError]: null,
  },
  getters: {
    [AuthTypes.getters.token](state) {
      return state.user.signInUserSession.idToken.jwtToken;
    },
    [AuthTypes.getters.email](state) {
      return state.user.signInUserSession.idToken.payload.email;
    },
  },
  mutations: {
    [AuthTypes.mutations.authentication](state, user) {
      state.user = user;
      state.loggedIn = true;
      state.authError = null;

      localStorage.setItem(AuthTypes.constants.LOCAL_STORAGE_USER, JSON.stringify(state.user));
      localStorage.setItem(
        AuthTypes.constants.LOCAL_STORAGE_LOGGED_IN, JSON.stringify(state.loggedIn),
      );
    },
    [AuthTypes.mutations.newPasswordRequried](state, user) {
      state.user = user;
      state.loggedIn = false;
      state.authError = null;

      localStorage.setItem(AuthTypes.constants.LOCAL_STORAGE_USER, JSON.stringify(state.user));
      localStorage.setItem(
        AuthTypes.constants.LOCAL_STORAGE_LOGGED_IN, JSON.stringify(state.loggedIn),
      );
    },
    [AuthTypes.mutations.authenticationError](state, error) {
      state.authError = error;
    },
    [AuthTypes.mutations.logout](state) {
      state.user = null;
      state.loggedIn = false;
      localStorage.clear(AuthTypes.constants.LOCAL_STORAGE_USER);
      localStorage.clear(AuthTypes.constants.LOCAL_STORAGE_LOGGED_IN);
    },
    [AuthTypes.mutations.logoutError](state, error) {
      state.authError = error;
    },
    [AuthTypes.mutations.refreshToken](state, tokenData) {
      state.user = { ...state.user, signInUserSession: tokenData };
      localStorage.setItem(AuthTypes.constants.LOCAL_STORAGE_USER, JSON.stringify(state.user));
    },
  },
  actions: {
    async [AuthTypes.actions.authenticate]({ commit }, { email, password }) {
      try {
        const user = await Auth.signIn(email, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          commit(AuthTypes.mutations.newPasswordRequried, user);
          return AuthTypes.notifications.AUTHENTICATE_NEW_PASSWORD;
        }

        commit(AuthTypes.mutations.authentication, user);
        return AuthTypes.notifications.AUTHENTICATE_SUCCESS;
      } catch (e) {
        commit(AuthTypes.mutations.authenticationError, e.message);
        return null;
      }
    },
    async [AuthTypes.actions.provideNewPassword]({ commit }, { user, password }) {
      try {
        await Auth.completeNewPassword(
          user,
          password,
        );
        commit(AuthTypes.mutations.authentication, user);
        return AuthTypes.notifications.PROVIDE_NEW_PASSWORD_SUCCESS;
      } catch (e) {
        commit(AuthTypes.mutations.authenticationError, e.message);
        return null;
      }
    },
    async [AuthTypes.actions.logout]({ commit }) {
      try {
        await Auth.signOut();
        commit(AuthTypes.mutations.logout);
        return AuthTypes.notifications.LOGOUT_SUCCEED;
      } catch (e) {
        commit(AuthTypes.mutations.logoutError, e.message);
        return null;
      }
    },
    async [AuthTypes.actions.refreshToken]({ commit }) {
      const session = await Auth.currentSession();
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const refreshToken = session.getRefreshToken();

      return new Promise((resolve) => {
        authenticatedUser.refreshSession(refreshToken, (err, data) => {
          if (err) {
            commit(AuthTypes.mutations.logout);
            resolve(null);
          }

          commit(AuthTypes.mutations.refreshToken, data);
          resolve(data.getIdToken().getJwtToken());
        });
      });
    },
  },
  namespaced: true,
};
