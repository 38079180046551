<template>
  <v-app-bar dark color="secondary" class="application-header">
    <v-toolbar-title>Client Migration Administration</v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="user-email">
      {{ email }}
    </div>

    <v-btn outlined @click="onLogout">
      <v-icon left>
        mdi-export
      </v-icon>
      Logout
    </v-btn>
  </v-app-bar>
</template>

<script>
import router from '@/router';
import { AuthTypes, AUTH_STORE } from '@/store/auth';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useActions, useGetters } = createNamespacedHelpers(AUTH_STORE);

export default {
  name: 'Header',
  setup() {
    const { logout } = useActions([AuthTypes.actions.logout]);
    const { email } = useGetters([AuthTypes.getters.email]);

    async function onLogout() {
      const result = await logout();
      if (result === AuthTypes.notifications.LOGOUT_SUCCEED) {
        router.push({ name: 'Login' });
      }
    }

    return {
      onLogout,
      email,
    };
  },
};
</script>

<style lang="scss" scoped>
  .application-header {
    .user-email {
      margin-right: 16px;
    }
  }
</style>
