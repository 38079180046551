import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth';
import { client } from './client';
import { loader } from './loader';
import { credentials } from './credentials';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    client,
    loader,
    credentials,
  },
});
