import store from '@/store';
import { NamespacedAuthTypes } from '@/store/auth';
import { NamespacedLoaderTypes } from '@/store/loader';
import api from './index';

export default function interceptors() {
  api.interceptors.request.use(
    (config) => {
      store.commit(NamespacedLoaderTypes.mutations.loading, true);
      const token = store.getters[NamespacedAuthTypes.getters.token];
      const updatedConfig = config;
      if (token) {
        updatedConfig.headers.common.Authorization = token;
      }

      return updatedConfig;
    },
    (error) => {
      store.commit(NamespacedLoaderTypes.mutations.loading, false);
      Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => {
      store.commit(NamespacedLoaderTypes.mutations.loading, false);
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      }

      return Promise.reject(response);
    },
    (error) => {
      store.commit(NamespacedLoaderTypes.mutations.loading, false);
      if (error.response.status === 401) {
        return store.dispatch(NamespacedAuthTypes.actions.refreshToken).then((token) => {
          if (token) {
            const { config } = error.response;
            config.headers.Authorization = token;
            return api.request(config);
          }

          return Promise.reject(error);
        });
      }

      return Promise.reject(error.response.data);
    },
  );
}
