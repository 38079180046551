import VueCompositionAPI from '@vue/composition-api';
import Amplify from 'aws-amplify';
import Vue from 'vue';
import api from '@/api';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import awsconfig from './aws-exports';
import interceptors from './api/inerceptors';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.prototype.$http = api;
interceptors();
Vue.use(VueCompositionAPI);
Amplify.configure(awsconfig);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
