export default function removeNamespace(namespace, types) {
  return Object.keys(types).reduce((r, key) => {
    const result = r;
    result[key] = Object.keys(types[key]).reduce((ir, innerKey) => {
      const innerResult = ir;
      innerResult[innerKey] = types[key][innerKey].replace(namespace, '');
      return innerResult;
    }, {});
    return result;
  }, {});
}
