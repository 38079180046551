import axios from '@/api';
import removeNamespace from './store-helper';

export const CREDENTIALS_STORE = 'credentials';

export const NamespacedCredentialsTypes = {
  actions: {
    request: 'credentials/request',
  },
};

export const CredentialsTypes = removeNamespace('credentials/', NamespacedCredentialsTypes);

export const credentials = {
  actions: {
    async [CredentialsTypes.actions.request](_, client) {
      try {
        const { data } = await axios.get('/credentials', { params: { client } });
        return data;
      } catch (e) {
        return { accessKey: '', secretKey: '' };
      }
    },
  },
  namespaced: true,
};
