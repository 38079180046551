<template>
  <v-app>
    <div class="app-container">
      <div class="header" v-if="loggedIn">
        <Header></Header>
      </div>

      <div class="content">
        <router-view></router-view>
      </div>

      <ProgressSpinner></ProgressSpinner>
    </div>
  </v-app>
</template>

<script lang="ts">
import Header from '@/components/Header.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import ProgressSpinner from '@/components/ProgressSpinner.vue';

const { useState } = createNamespacedHelpers('auth');

export default {
  name: 'App',
  components: {
    Header,
    ProgressSpinner,
  },
  setup() {
    const { loggedIn } = useState(['loggedIn']);

    return {
      loggedIn,
    };
  },
};
</script>

<style lang="scss">
  body {
    margin: 0;
    font-family: 'Roboto', serif;
    background-color: #dddddd;
    color: #ffffff;
  }

  .app-container {
    display: flex;
    flex-flow: column;
    height: 100vh;

    .content {
      padding: 32px;
      flex: 1 1 auto;
    }
  }

  .dialog-header {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: 10px;
    }
  }

  .dialog-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button:not(:last-child) {
      margin-right: 8px;
    }
  }

  .v-input.v-text-field {
    margin-top: 10px;
  }
</style>
