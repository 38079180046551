import removeNamespace from './store-helper';

export const LOADING_STORE = 'loader';

export const NamespacedLoaderTypes = {
  state: {
    loading: 'loader/loading',
    loadingRef: 'loader/loadingRef',
  },
  mutations: {
    loading: 'loader/loading',
  },
};

export const LoaderTypes = removeNamespace('loader/', NamespacedLoaderTypes);

export const loader = {
  state: {
    loading: false,
    loadingRef: 0,
  },
  mutations: {
    [LoaderTypes.mutations.loading](state, isLoading) {
      if (isLoading) {
        state.loadingRef += 1;
        state.loading = true;
      } else if (state.loadingRef > 0) {
        state.loadingRef -= 1;
        state.loading = state.loadingRef > 0;
      }
    },
  },
  namespaced: true,
};
