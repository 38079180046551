import axios from '@/api';
import removeNamespace from './store-helper';

export const CLIENT_STORE = 'client';

export const NamespacedClientTypes = {
  state: {
    clients: 'client/clients',
    clientsLoadError: 'client/clientsLoadError',
    addNewClientError: 'client/addNewClientError',
  },
  mutations: {
    clientsLoaded: 'client/clientsLoaded',
    clientsLoadError: 'client/clientsLoadError',
    newClientAdded: 'client/newClientAdded',
    addNewClientError: 'client/addNewClientError',
    removeNewClientError: 'client/removeNewClientError',
  },
  actions: {
    loadClients: 'client/loadClients',
    createNewClient: 'client/createNewClient',
    removeNewClientError: 'client/removeNewClientError',
  },
  notifications: {
    CREATE_NEW_CLIENT_SUCCESS: 'CREATE_NEW_CLIENT_SUCCESS',
  },
};

export const ClientTypes = removeNamespace('client/', NamespacedClientTypes);

export const client = {
  state: {
    [ClientTypes.state.clients]: [],
    [ClientTypes.state.clientsLoadError]: null,
    [ClientTypes.state.addNewClientError]: null,
  },
  mutations: {
    [ClientTypes.mutations.clientsLoaded](state, clients) {
      state.clients = clients;
      state.clientsLoadError = null;
    },
    [ClientTypes.mutations.clientsLoadError](state, error) {
      state.clientsLoadError = error;
    },
    [ClientTypes.mutations.newClientAdded](state, newClient) {
      state.clients = [newClient, ...state.clients];
      state.clientsLoadError = null;
    },
    [ClientTypes.mutations.addNewClientError](state, error) {
      state.addNewClientError = error;
    },
    [ClientTypes.mutations.removeNewClientError](state) {
      state.addNewClientError = null;
    },
  },
  actions: {
    async [ClientTypes.actions.loadClients]({ commit }) {
      try {
        const { data } = await axios.get('/clients');
        commit(ClientTypes.mutations.clientsLoaded, data);
      } catch (e) {
        commit(ClientTypes.mutations.clientsLoadError, e.message);
      }
    },
    async [ClientTypes.actions.createNewClient]({ commit }, newClient) {
      try {
        const { data } = await axios.post('/clients', newClient);
        commit(ClientTypes.mutations.newClientAdded, data);
        return ClientTypes.notifications.CREATE_NEW_CLIENT_SUCCESS;
      } catch (e) {
        commit(ClientTypes.mutations.addNewClientError, e.message);
        return null;
      }
    },
    [ClientTypes.actions.removeNewClientError]({ commit }) {
      commit(ClientTypes.mutations.removeNewClientError);
    },
  },
  namespaced: true,
};
