<template>
  <v-overlay :value="loading" :z-index="999">
    <v-progress-circular
      :size="100"
      :width="10"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import { LoaderTypes, LOADING_STORE } from '@/store/loader';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useState } = createNamespacedHelpers(LOADING_STORE);

export default {
  name: 'ProgressSpinner',
  setup() {
    const { loading } = useState([LoaderTypes.state.loading]);

    return {
      loading,
    };
  },
};
</script>
