import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { requiresAuth: false, requiresUser: false, requiresNoAuth: true },
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: () => import(/* webpackChunkName: "new-password" */ '../views/NewPassword.vue'),
    meta: { requiresAuth: false, requiresUser: true, requiresNoAuth: true },
  },
  {
    path: '/',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/Clients.vue'),
    meta: { requiresAuth: true, notLoggedIn: false, requiresNoAuth: false },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.loggedIn) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  }

  if (to.matched.some((record) => record.meta.requiresUser)) {
    if (store.state.auth.user) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  }

  if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (!store.state.auth.loggedIn) {
      next();
    } else {
      next({
        path: '/',
      });
    }
  }

  next();
});

export default router;
